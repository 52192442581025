import { render, staticRenderFns } from "./HeaderComponent.vue?vue&type=template&id=61c2df06&scoped=true"
import script from "./HeaderComponent.vue?vue&type=script&lang=ts"
export * from "./HeaderComponent.vue?vue&type=script&lang=ts"
import style0 from "./HeaderComponent.vue?vue&type=style&index=0&id=61c2df06&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c2df06",
  null
  
)

export default component.exports
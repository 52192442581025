
import { Vue, Component } from "vue-property-decorator"
import { namespace } from "vuex-class"
import ShoppingCart from "../components/ShoppingCart.vue"

const Products = namespace("products")
const Cart = namespace("cart")

@Component({
	name: "ProductPage",
	components: {
		ShoppingCart,
	},
})
export default class ProductPage extends Vue {
	@Products.Getter getById: any
	@Products.Getter getShopSize: any
	@Products.Getter getConfigCombinations: any
	@Products.Getter getConfigIds: any
	@Products.Action getAllProducts: any
	@Products.Action addNewProduct: any
	@Products.Action addNewConfigCombinations: any
	@Products.Action addNewConfigIds: any
	@Cart.Action addProductToCart: any
	get myCarousel(): any {
		return this.$refs.carousel
	}

	get product() {
		return this.getById(this.$route.params.id)
	}

	get configCombinations() {
		return this.getConfigCombinations()
	}

	get configIds() {
		return this.getConfigIds()
	}

	get shopSize() {
		return this.getShopSize()
	}

	get config() {
		let str = ""
		for (let i = 0; i < this.product.checkList.length; i++) {
			const element = this.product.checkList[i]
			str += element
		}
		return str
	}

	get SummaryPrice() {
		let sum = 0
		for (let i = 0; i < this.product.checkList.length; i++) {
			const element = this.product.checkList[i]
			sum += this.product.configurator[element]
		}
		return this.product.price + sum
	}

	created() {
		this.getAllProducts()
	}

	setActiveItem(index) {
		this.myCarousel.setActiveItem(index)
	}

	contains(arr, arr2) {
		if (arr.length != arr2.length) return false
		let on = 0
		for (let i = 0; i < arr.length; i++) {
			for (let j = 0; j < arr2.length; j++) {
				if (arr[i] === arr2[j]) {
					on++
					break
				}
			}
		}
		return on == arr.length ? true : false
	}

	addThisProductToCart(product) {
		if (product.checkList.length > 0) {
			let doWeHaveConfig = false
			for (let i = 0; i < this.configCombinations.length; i++) {
				if (this.contains(this.configCombinations[i], this.config)) {
					this.addProductToCart(this.getById(this.configIds[i]))
					doWeHaveConfig = true
					break
				}
			}

			if (!doWeHaveConfig) {
				const newProduct = {
					id: this.shopSize + 1,
					title: product.title,
					price: product.price,
					inventory: product.inventory,
					images: product.images,
					configurator: product.configurator,
					checked: product.checked,
					checkList: product.checkList,
					totalPrice: this.SummaryPrice,
				}
				this.addNewConfigCombinations(this.config)
				this.addNewConfigIds(this.shopSize + 1)
				this.addNewProduct(newProduct)
				this.addProductToCart(newProduct)
			}
		} else {
			this.addProductToCart(product)
		}
	}
}

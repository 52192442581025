
import { Vue, Component } from "vue-property-decorator"

@Component({})
export default class HeaderComponent extends Vue {
	activeIndex: string = "1"
	menuIsActive: boolean = false
	windowWidth: number = 0
	windowWidthFlag: boolean = true

	lang: string = "ru"

	menuNav() {
		this.menuIsActive = !this.menuIsActive
	}

	switchLang() {
		this.$i18n.locale = this.lang
	}

	getWindowWidth() {
		this.windowWidth = document.documentElement.clientWidth
		if (this.windowWidth > 960) {
			this.windowWidthFlag = true
		} else {
			this.windowWidthFlag = false
		}
	}

	mounted() {
		this.$nextTick(function() {
			window.addEventListener("resize", this.getWindowWidth)

			//Init
			this.getWindowWidth()
		})
	}

	beforeDestroy() {
		window.removeEventListener("resize", this.getWindowWidth)
	}
}
